import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'
import GoogleMapsApiLoader from 'google-maps-api-loader'

Vue.use(VueRouter)

const router = new VueRouter({
  activeClass: 'active',
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        permission: '*',
        resource: 'Auth',
        redirectIfLoggedIn: true,
        removeFilterData: true,
        childRoute: false,
      },
    },
    {
      path: '/reset-password/:token',
      name: 'auth-reset',
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        permission: '*',
        layout: 'full',
      },
    },
    {
      path: '/admin-reset-password/:token',
      name: 'admin-auth-reset',
      component: () => import('@/views/AdminResetPassword.vue'),
      meta: {
        permission: '*',
        layout: 'full',
        removeFilterData: true,
        childRoute: false,
      },
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/home/Home.vue'),
      meta: {
        pageTitle: 'Home',
        auth: true,
        permission: '*',
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/',
      name: 'admins',
      components: {
        default: () => import('@/views/home/Home.vue'),
        PreferencesRouterView: () => import('@/views/admins/AdminsList.vue'),
      },
      meta: {
        pageTitle: 'Admins',
        permission: 'View Admins',
        auth: true,
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        breadcrumb: [
          {
            text: 'Admins',
            active: true,

          },
        ],
      },
    },
    {
      path: '/roadmap',
      name: 'roadmap',
      component: () => import('@/views/roadmap/RoadMap.vue'),
      meta: {
        pageTitle: 'Road map',
        auth: true,
        permission: '*',
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        breadcrumb: [
          {
            text: 'Roadmap',
            active: true,
          },
        ],
      },
    },
    {
      path: '/',
      name: 'release-notes',
      components: {
        default: () => import('@/views/home/Home.vue'),
        PreferencesRouterView: () => import('@/views/release-notes/ReleaseNotesList.vue'),
      },
      meta: {
        navActiveLink: 'home',
        pageTitle: 'Release Notes',
        auth: true,
        permission: '*',
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        breadcrumb: [
          {
            text: 'Release Notes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/',
      name: 'edit.release-notes',
      components: {
        default: () => import('@/views/home/Home.vue'),
        PreferencesRouterView: () => import('@/views/release-notes/ReleaseNoteForm.vue'),
      },
      meta: {
        navActiveLink: 'home',
        pageTitle: 'Edit Release Notes',
        auth: true,
        permission: 'Edit Release Notes',
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        breadcrumb: [
          {
            text: 'Edit Release Notes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/roadmap/edit',
      name: 'roadmap.edit',
      component: () => import('@/views/roadmap/EditRoadMap.vue'),
      meta: {
        pageTitle: 'Update RoadMap',
        auth: true,
        permission: '*',
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        breadcrumb: [
          {
            text: 'Edit RoadMap',
            active: true,
          },
        ],
      },
    },
    {
      path: '/customers',
      name: 'customers',
      component: () => import('@/views/users/UsersList.vue'),
      meta: {
        pageTitle: 'Customers',
        auth: true,
        permission: 'View Customers',
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        breadcrumb: [
          {
            text: 'Customers',
            active: true,
          },
        ],
      },
    },
    {
      path: '/customers/:id',
      name: 'view-customer',
      component: () => import('@/views/users/UserDetails.vue'),
      meta: {
        navActiveLink: 'customers',
        pageTitle: 'Customer',
        auth: true,
        permission: 'View Customers',
        refreshUserData: true,
        removeFilterData: false,
        childRoute: true,
        breadcrumb: [
          {
            text: 'Customer Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rides-incidents',
      name: 'rides-incidents',
      component: () => import('@/views/rides-incidents/RideIncidents.vue'),
      meta: {
        pageTitle: 'Rides Incidents Reports',
        permission: 'View Rides',
        auth: true,
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        breadcrumb: [
          {
            text: 'Rides',
            active: true,
          },
          {
            text: 'Rides Incidents',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rides',
      name: 'rides',
      component: () => import('@/views/rides/RidesList.vue'),
      meta: {
        pageTitle: 'Rides',
        permission: 'View Rides',
        auth: true,
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        breadcrumb: [
          {
            text: 'Rides',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rides/:id',
      name: 'view-ride',
      component: () => import('@/views/rides/Viewride.vue'),
      meta: {
        navActiveLink: 'rides',
        auth: true,
        permission: 'View Rides',
        pageTitle: 'View Ride',
        refreshUserData: true,
        removeFilterData: false,
        childRoute: true,
        breadcrumb: [
          {
            text: 'Rides',
            to: { name: 'rides' },
          },
          {
            text: 'View',
            active: true,
          },
        ],
      },
    },
    {
      path: '/scooters',
      name: 'scooters',
      component: () => import('@/views/scooters/ScootersList.vue'),
      meta: {
        pageTitle: 'Scooters',
        permission: 'View Scooters',
        auth: true,
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        breadcrumb: [
          {
            text: 'Scooters',
            active: true,
          },
        ],
      },
    },
    {
      path: '/scooters/:id',
      name: 'view-scooter',
      component: () => import('@/views/scooters/ScooterDetails.vue'),
      meta: {
        navActiveLink: 'scooters',
        auth: true,
        permission: 'View Scooters',
        pageTitle: 'View Scooter',
        refreshUserData: true,
        removeFilterData: false,
        childRoute: true,
        breadcrumb: [
          {
            text: 'Scooters',
            to: { name: 'scooter' },
          },
          {
            text: 'Scooter',
            active: true,
          },
        ],
      },
    },
    {
      path: '/scooters/:id/location',
      name: 'scooter-location',
      component: () => import('@/views/scooters/ScooterLocation.vue'),
      meta: {
        navActiveLink: 'scooters',
        auth: true,
        permission: 'View Scooters',
        pageTitle: 'View Scooter Location',
        refreshUserData: true,
        removeFilterData: false,
        childRoute: true,
        breadcrumb: [
          {
            text: 'Scooters',
            to: { name: 'scooter' },
          },
          {
            text: 'Scooter',
            active: true,
          },
        ],
      },
    },
    {
      path: '/',
      name: 'service-locators',
      components: {
        default: () => import('@/views/home/Home.vue'),
        PreferencesRouterView: () => import('@/views/service-locators/ServiceLocatorsList.vue'),
      },
      meta: {
        pageTitle: 'Service Locators',
        permission: 'View Service Locators',
        auth: true,
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        breadcrumb: [
          {
            text: 'Service Locators',
            active: true,
          },
        ],
      },
    },
    {
      path: '/',
      name: 'service-locators-form',
      components: {
        default: () => import('@/views/home/Home.vue'),
        PreferencesRouterView: () => import('@/views/service-locators/ServiceLocatorForm.vue'),
      },
      meta: {
        navActiveLink: 'service-locators',
        pageTitle: 'Service Locator',
        auth: true,
        permission: 'View Service Locators',
        refreshUserData: true,
        removeFilterData: false,
        childRoute: true,
        breadcrumb: [
          {
            text: 'Service Locator',
            active: true,
          },
        ],
      },
    },
    {
      path: '/',
      name: 'roles',
      components: {
        default: () => import('@/views/home/Home.vue'),
        PreferencesRouterView: () => import('@/views/roles/RolesList.vue'),
      },
      meta: {
        // navActiveLink: 'roles',
        auth: true,
        permission: 'View Roles',
        pageTitle: 'Roles',
        refreshUserData: true,
        removeFilterData: true,
        // breadcrumb: [
        //   {
        //     text: 'Roles List',
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: '/',
      name: 'add-role',
      abstract: true,
      view: 'PreferencesRouterView',
      components: {
        default: () => import('@/views/home/Home.vue'),
        PreferencesRouterView: () => import('@/views/roles/NewRole.vue'),
      },
      meta: {
        navActiveLink: 'roles',
        auth: true,
        permission: 'Create Role',
        pageTitle: 'Role',
        refreshUserData: true,
        removeFilterData: false,
        childRoute: true,
      },
    },
    {
      path: '/roles/:id/edit',
      name: 'edit-role',
      components: {
        default: () => import('@/views/home/Home.vue'),
        PreferencesRouterView: () => import('@/views/roles/NewRole.vue'),
      },
      meta: {
        navActiveLink: 'roles',
        auth: true,
        permission: 'Edit Role',
        pageTitle: 'Role',
        refreshUserData: true,
        removeFilterData: false,
        childRoute: true,
      },
    },
    {
      path: '/',
      name: 'logs',
      components: {
        default: () => import('@/views/home/Home.vue'),
        PreferencesRouterView: () => import('@/views/logs/LogsList.vue'),
      },
      meta: {
        // navActiveLink: 'logs',
        auth: true,
        permission: 'View Logs',
        pageTitle: 'Log',
        refreshUserData: true,
        removeFilterData: true,
      },
    },
    {
      path: '/',
      name: 'admin-logs',
      components: {
        default: () => import('@/views/home/Home.vue'),
        PreferencesRouterView: () => import('@/views/logs/AdminsLogs.vue'),
      },
      meta: {
        // navActiveLink: 'admin-logs',
        auth: true,
        permission: 'View Logs',
        pageTitle: 'Admins Logs',
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        breadcrumb: [
          {
            text: 'Admins Logs',
            active: true,
          },
        ],
      },
    },
    {
      path: '/factory',
      name: 'factory',
      component: () => import('@/views/factory-monitor/factory-monitor.vue'),
      meta: {
        navActiveLink: 'factory',
        auth: true,
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        permission: 'View Factory Monitor',
      },
    },
    {
      path: '/factory/calendar',
      name: 'factory.calendar',
      component: () => import('@/views/factory-monitor/factory-calendar.vue'),
      meta: {
        navActiveLink: 'factory.calendar',
        auth: true,
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        permission: 'View Factory Monitor',
      },
    },
    {
      path: '/city-2022/issues',
      name: 'issues',
      component: () => import('@/views/city-2022/issues/Overview.vue'),
      meta: {
        navActiveLink: 'issues',
        auth: true,
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        permission: '*',
      },
    },
    {
      path: '/city-2022/customers-list',
      name: 'city_2022_customers_list',
      component: () => import('@/views/city-2022/speed-consents/CustomersList.vue'),
      meta: {
        navActiveLink: 'city_2022_customers_list',
        auth: true,
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        permission: '*',
      },
    },
    {
      path: '/city-2022/latest-issues',
      name: 'latest_issues',
      component: () => import('@/views/city-2022/issues/Latest.vue'),
      meta: {
        navActiveLink: 'latest_issues',
        auth: true,
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        permission: '*',
      },
    },
    {
      path: '/statistics',
      name: 'statistics',
      component: () => import('@/views/PowerPi.vue'),
      meta: {
        navActiveLink: 'statistics',
        auth: true,
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        permission: 'View Statistics',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        permission: '*',
        layout: 'full',
        childRoute: false,
        removeFilterData: true,
      },
    },
    {
      path: '*',
      permission: '*',
      redirect: 'error-404',
    },
    {
      path: '/target',
      name: 'target',
      component: () => import('@/views/Target/Index.vue'),
      meta: {
        pageTitle: 'Target',
        auth: true,
        permission: '*',
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        breadcrumb: [
          {
            text: 'Target',
            active: true,
          },
        ],
      },
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: () => import('@/views/maintenance/backup.vue'),
      meta: {
        pageTitle: 'Maintenance',
        auth: true,
        permission: '*',
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        breadcrumb: [
          {
            text: 'Maintenance',
            active: true,
          },
        ],
      },
    },
    {
      path: '/',
      name: 'configurator',
      components: {
        default: () => import('@/views/home/Home.vue'),
        PreferencesRouterView: () => import('@/views/settings/Configurator.vue'),
      },
      meta: {
        pageTitle: 'Configurator',
        auth: true,
        permission: '*',
        refreshUserData: true,
        removeFilterData: true,
        childRoute: false,
        breadcrumb: [
          {
            text: 'Configurator',
            active: true,
          },
        ],
      },
    },
  ],
})

router.beforeEach((to, _, next) => {
  if (window.firebaseLogEvent) {
    window.firebaseLogEvent(window.firebaseAnalytics, 'page_view', {
      path: to.fullPath,
    })
  }
  const isLoggedIn = isUserLoggedIn()

  const loadMapPagesArray = ['view-ride', 'view-scooter', 'service-locators']
  if (loadMapPagesArray.indexOf(to.name) >= 0) {
    if (!Vue.prototype.$googleLoaded) {
      GoogleMapsApiLoader({
        apiKey: 'AIzaSyCnpNoSCz5YBcJem2z4ihqYBHxv4m_zQS8',
        libraries: ['places'],
      }).then(googleMapApi => {
        Vue.prototype.$googleMap = googleMapApi
        Vue.prototype.$googleLoaded = true
      })
    }
  }
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (to.matched.some(route => route.meta.auth) && !isLoggedIn) return next({ name: 'auth-login' })
  }

  const permissions = JSON.parse(localStorage.getItem('permissions')) || []

  if (!to.matched.some(route => (route.meta.permission === '*') || permissions.includes(route.meta.permission))) return next({ name: 'error-404' })

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  if (to.meta.refreshUserData) {
    Vue.prototype.$http.post(Vue.prototype.$resources.admins.permissions)
      .then(response => {
        if (response.data.status === 200) {
          // eslint-disable-next-line no-shadow
          const { data: { permissions } } = response.data
          localStorage.setItem('permissions', JSON.stringify(permissions))
        }
      })
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }

  if (to.meta.removeFilterData && !to.meta.childRoute && !from.meta.childRoute) {
    localStorage.removeItem('list-filter')
  }
})
export default router
